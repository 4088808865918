import React, { Suspense } from "react";

// this is fallback component cant make it lazy loading

import RMADeclaration from "@/pages/RMADeclaration/RMADeclaration";
import RMADeclarationManage from "@/pages/RMADeclaration/RMADeclarationManage";
import RMADeclarationView from "@/pages/RMADeclaration/RMADeclarationView";
import RMADeclarationViewDetails from "@/pages/RMADeclaration/RMADeclarationViewDetails";
import MetarWeatherLocation from "@pages/MetarWeatherLocation/MetarWeatherLocation.js";
import MetarWeatherLocationManage from "@pages/MetarWeatherLocation/MetarWeatherLocationManage.js";
import ImportTrackerSettings from "@pages/TrackersAdmin/ImportTrackerSettings.js";
import Loading from "../../components/ReactBootstrap/LoaderSpinner/Loading";
import SystemTagList from "@/pages/SystemTag/SystemTagList";
import SystemTagManage from "@/pages/SystemTag/SystemTagManage";
import PartnerTagManage from "@/pages/PartnerTag/PartnerTagManage";
import PartnerTagList from "@/pages/PartnerTag/PartnerTagList";
import WifiCellularMismatch from "@/pages/WifiCellularMismatch/WifiCellularMismatch";
import NoAccessPage from "@/cleanup/pages/NoAccessPage/NoAccessPage";

const AppLayout = React.lazy(() => import("../AdminLayout/AppLayout"));

const ErrorPage = React.lazy(() => import("@pages/ErrorPage/ErrorPage"));
const PermissionPage = React.lazy(() =>
  import("@cleanup/pages/PermissionPage/PermissionPage")
);
const NotFoundPage = React.lazy(() =>
  import("@pages/NotFoundPage/NotFoundPage")
);

const Helpdesk = React.lazy(() => import("@pages/Helpdesk/Helpdesk"));
const ZendeskSupportTicket = React.lazy(() =>
  import("@/pages/ZendeskSupportTicket/ZendeskSupportTicket")
);
const ZendeskOrderTicket = React.lazy(() =>
  import("@/pages/ZendeskOrderTicket/ZendeskOrderTicket")
);
const MaintenanceDoneTable = React.lazy(() =>
  import("@pages/MaintenanceDoneTable/MaintenanceDoneTable")
);
const MaintenanceUpcomingTable = React.lazy(() =>
  import("@pages/MaintenanceUpcomingTable/MaintenanceUpcomingTable")
);
const Invoicing = React.lazy(() => import("@pages/Invoicing/Invoicing"));
const Support = React.lazy(() => import("@/cleanup/pages/Support/Support"));
const InvoiceDetail = React.lazy(() =>
  import("@pages/InvoiceDetail/InvoiceDetail")
);
const OrganisationVisionPartnerUpdate = React.lazy(() =>
  import("@pages/PartnerUpdate/OrganisationVisionPartnerUpdate")
);
const SigfoxAccountDeviceTypeInfo = React.lazy(() =>
  import("@pages/SigfoxAccountDeviceTypeInfo/SigfoxAccountDeviceTypeInfo")
);
const SigfoxAccountUpdate = React.lazy(() =>
  import("@pages/SigfoxAccountUpdate/SigfoxAccountUpdate")
);
const AlertRuleUpdate = React.lazy(() =>
  import("@pages/AlertUpdate/AlertRuleUpdate")
);
const RoleManagerUpdate = React.lazy(() =>
  import("@pages/RoleManagerUpdate/RoleManagerUpdate")
);
const InvoiceAddendum = React.lazy(() =>
  import("@pages/InvoiceAddendum/InvoiceAddendum")
);
const OrganizationLandingPage = React.lazy(() =>
  import("@pages/DefaultLandingPage/OrganizationLandingPage")
);
const GeozoneStatistics = React.lazy(() =>
  import("@pages/GeozoneVisit/GeozoneStatistics")
);
const UserUpdatePage = React.lazy(() =>
  import("@cleanup/pages/UserUpdate/UserUpdatePage")
);
const Profile = React.lazy(() => import("@pages/Profile/Profile"));
const TrackerProductDetail = React.lazy(() =>
  import("@pages/TrackerProductDetail/TrackerProductDetail")
);
const ProductAutoUpgrade = React.lazy(() =>
  import("@pages/ProductAutoUpgrade/ProductAutoUpgrade")
);
const ProductAutoUpgradeDetail = React.lazy(() =>
  import("@pages/ProductAutoUpgrade/ProductAutoUpgradeDetail")
);
const ProductRegexpFilter = React.lazy(() =>
  import("@pages/ProductRegexpFilter/ProductRegexpFilter")
);
const ProductRegexpFilterDetail = React.lazy(() =>
  import("@pages/ProductRegexpFilter/ProductRegexpFilterDetail")
);
const UpdateDeviceTag = React.lazy(() =>
  import("@pages/AssetTagManage/UpdateDeviceTag")
);
const UpdateGeozoneTag = React.lazy(() =>
  import("@pages/GeozoneTagManage/UpdateGeozoneTag")
);
const BiDashBoardManagePage = React.lazy(() =>
  import("@cleanup/pages/BiDashBoardManage/BiDashBoardManagePage")
);
const CustomI18nMessages = React.lazy(() =>
  import("@pages/CustomI18nMessages/CustomI18nMessages")
);
const CustomI18nMessagesManage = React.lazy(() =>
  import("@pages/CustomI18nMessages/CustomI18nMessagesManage")
);
const MainManufacturingInfo = React.lazy(() =>
  import("@pages/ManufacturingInfo/MainManufacturingInfo")
);
const AuditLog = React.lazy(() => import("@pages/AuditLog/AuditLog"));
const AuditLogView = React.lazy(() => import("@pages/AuditLog/AuditLogView"));
const LoginMobile = React.lazy(() => import("@pages/Login/LoginMobile"));
const Stock = React.lazy(() => import("@pages/Stock/Stock"));
const StockUpdate = React.lazy(() => import("@pages/StockUpdate/StockUpdate"));
const OrganisationDemoSettings = React.lazy(() =>
  import("@pages/OrganisationDemoSettings/OrganisationDemoSettings")
);
const OrganisationDemoSettingsManage = React.lazy(() =>
  import("@pages/OrganisationDemoSettings/OrganisationDemoSettingsManage")
);
const LegalDocument = React.lazy(() => import("@pages/Legal/LegalDocument"));
const LegalDocumentTemplate = React.lazy(() =>
  import("@pages/Legal/LegalDocumentTemplate")
);
const LegalDocumentTemplateManage = React.lazy(() =>
  import("@pages/Legal/LegalDocumentTemplateManage")
);
const LegalDocumentImport = React.lazy(() =>
  import("@pages/Legal/LegalDocumentImport")
);
const LegalDocumentSend = React.lazy(() =>
  import("@pages/Legal/LegalDocumentSend")
);
const LocationHistory = React.lazy(() =>
  import("@pages/LocationHistory/LocationHistory")
);
const QuotationDetail = React.lazy(() =>
  import("@pages/QuotationDetail/QuotationDetail")
);
const InvoicingOperations = React.lazy(() =>
  import("@pages/InvoicingOperations/InvoicingOperations")
);
const InvoicingOperationsDetail = React.lazy(() =>
  import("@pages/InvoicingOperations/InvoicingOperationsDetail")
);
const GeolayerGenerator = React.lazy(() =>
  import("@pages/GeolayerGenerator/GeolayerGenerator")
);
const GeolayerGeneratorManage = React.lazy(() =>
  import("@pages/GeolayerGenerator/GeolayerGeneratorManage")
);
const LegalDocumentView = React.lazy(() =>
  import("@pages/Legal/LegalDocumentView")
);
const ProcessStatistics = React.lazy(() =>
  import("@pages/ProcessDashboard/ProcessStatistics")
);
const Processes = React.lazy(() => import("@pages/ProcessDashboard/Processes"));

const Login = React.lazy(() => import("../../pages/Login/Login"));
const OAuth2Authorize = React.lazy(() =>
  import("../../pages/OAuth2Authorize/OAuth2Authorize")
);
const Logout = React.lazy(() => import("../../pages/Logout/Logout"));
const Confirm = React.lazy(() => import("../../pages/Confirm/Confirm"));
const ForgetPassword = React.lazy(() =>
  import("../../pages/ForgetPassword/ForgetPassword")
);
const ChangePassword = React.lazy(() =>
  import("../../pages/ChangePassword/ChangePassword")
);
const EmailVerification = React.lazy(() =>
  import("../../pages/EmailVerification/EmailVerification")
);
const ChangePasswordExpired = React.lazy(() =>
  import("../../pages/ChangePasswordExpired/ChangePasswordExpired")
);
const CompleteUserInfo = React.lazy(() =>
  import("../../pages/CompleteUserInfo/CompleteUserInfo")
);
const ActivateInvitation = React.lazy(() =>
  import("../../pages/ActivateInvitation/ActivateInvitation")
);

const AssetMap = React.lazy(() => import("../../pages/AssetMap/AssetMap"));
const AssetListReact = React.lazy(() =>
  import("../../pages/AssetList/AssetList")
);
const AssetDetail = React.lazy(() =>
  import("../../pages/AssetDetail/AssetDetail")
);
const ApiDocs = React.lazy(() => import("../../pages/ApiDocs/ApiDocs"));
const ApiAccess = React.lazy(() => import("../../pages/ApiAccess/ApiAccess"));
const ClientAppApi = React.lazy(() =>
  import("../../pages/ClientAppApi/ClientAppApi")
);
const RoleManager = React.lazy(() =>
  import("../../pages/RoleManager/RoleManager")
);
const InternalApi = React.lazy(() =>
  import("../../pages/InternalApi/InternalApi")
);
const InternalPlan = React.lazy(() =>
  import("../../pages/InternalPlan/InternalPlan")
);
const Utilization = React.lazy(() =>
  import("../../pages/Utilization/Utilization")
);
const Manufacturing = React.lazy(() =>
  import("../../pages/Manufacturing/Manufacturing")
);
const ManufacturingOrderDetail = React.lazy(() =>
  import("../../pages/Manufacturing/ManufacturingOrderDetail")
);
const SuezDashBoard = React.lazy(() =>
  import("../../pages/SuezDashBoard/SuezDashBoard")
);
const Report = React.lazy(() => import("../../pages/Report/Report"));
const CreateReport = React.lazy(() =>
  import("../../pages/CreateReport/CreateReport")
);
const ReportSchedule = React.lazy(() =>
  import("../../pages/ReportSchedule/ReportSchedule")
);
const ActiveAlertsDashboard = React.lazy(() =>
  import("../../pages/ActiveAlertsDashboard/ActiveAlertsDashboard")
);
const HistoricalAlertsDashboard = React.lazy(() =>
  import("../../pages/HistoricalAlertsDashboard/HistoricalAlertsDashboard")
);
const ActiveAlertsList = React.lazy(() =>
  import("../../pages/ActiveAlertsList/ActiveAlertsList")
);
const HistoricalAlertsList = React.lazy(() =>
  import("../../pages/HistoricalAlertsList/HistoricalAlertsList")
);
const GeozonesDashboard = React.lazy(() =>
  import("../../pages/GeozonesDashboard/GeozonesDashboard")
);
const GeozonesList = React.lazy(() =>
  import("../../pages/GeozonesList/GeozonesList")
);
const GeozonesTagList = React.lazy(() =>
  import("../../pages/GeozonesTagList/GeozonesTagList")
);
const GeozoneTagManage = React.lazy(() =>
  import("../../pages/GeozoneTagManage/GeozoneTagManage")
);
const AssetTagManage = React.lazy(() =>
  import("../../pages/AssetTagManage/AssetTagManage")
);
const Geolayers = React.lazy(() => import("../../pages/Geolayers/Geolayers"));
const GeolayerUpdate = React.lazy(() =>
  import("../../pages/GeolayerUpdate/GeolayerUpdate")
);
const AssetTrackersAdmin = React.lazy(() =>
  import("../../pages/TrackersAdmin/AssetTrackerAdmin")
);
const TrackersAdminUpdate = React.lazy(() =>
  import("../../pages/TrackersAdminUpdate/TrackerAdminUpdate")
);
const FactoryUpload = React.lazy(() =>
  import("../../pages/FactoryUpload/FactoryUpload")
);
const FactoryUploadUpdate = React.lazy(() =>
  import("../../pages/FactoryUpload/FactoryUploadUpdate")
);
const CreateFactoryOrder = React.lazy(() =>
  import("../../pages/FactoryUpload/CreateFactoryOrder")
);
const AreasUpdate = React.lazy(() =>
  import("../../pages/AreasUpdate/AreasUpdate")
);
const InfrastructuresUpdate = React.lazy(() =>
  import("../../pages/InfrastructuresUpdate/InfrastructuresUpdate")
);
const Infrastructure = React.lazy(() =>
  import("../../pages/Infrastructure/Infrastructure.js")
);
const Alert = React.lazy(() => import("../../pages/Alert/Alert"));
const UtilizationKpi = React.lazy(() =>
  import("../../pages/UtilizationKpi/UtilizationKpi")
);
const UtilizationKpiUpdate = React.lazy(() =>
  import("../../pages/UtilizationKpiUpdate/UtilizationKpiUpdate")
);
const VisibilityFilters = React.lazy(() =>
  import("../../pages/VisibilityFilters/VisibilityFilters")
);
const ManageVisibilityFilters = React.lazy(() =>
  import("../../pages/VisibilityFilters/ManageVisibilityFilters")
);
const OrganisationFilters = React.lazy(() =>
  import(
    "../../pages/VisibilityFilters/OrganisationFilters/OrganisationFilters"
  )
);
const ManageOrganisationFilters = React.lazy(() =>
  import(
    "../../pages/VisibilityFilters/OrganisationFilters/ManageOrganisationFilters"
  )
);
const FlowDefinition = React.lazy(() =>
  import("../../pages/FlowDefinition/FlowDefinition")
);
const FlowSettings = React.lazy(() =>
  import("../../pages/FlowSettings/FlowSettings")
);
const ProcessDefinition = React.lazy(() =>
  import("../../pages/ProcessDefinition/ProcessDefinition")
);
const ProcessDefinitionUpdate = React.lazy(() =>
  import("../../pages/ProcessDefinitionUpdate/ProcessDefinitionUpdate")
);
const User = React.lazy(() => import("../../pages/User/User"));
const ImageLibrary = React.lazy(() =>
  import("../../cleanup/pages/ImageLibrary/ImageLibrary")
);
const ImageLibraryManage = React.lazy(() =>
  import("../../cleanup/pages/ImageLibrary/ImageLibraryManage")
);
const Organization = React.lazy(() =>
  import("../../pages/Organization/Organization")
);
const OrganizationDetail = React.lazy(() =>
  import("../../pages/OrganizationDetail/OrganizationDetail")
);
const ProductPlan = React.lazy(() =>
  import("../../pages/ProductPlan/ProductPlan")
);
const TrackerProduct = React.lazy(() =>
  import("../../pages/TrackerProduct/TrackerProduct")
);

const SingleSignOn = React.lazy(() =>
  import("../../pages/SingleSignOn/SingleSignOn")
);
const EndOfLifeDevice = React.lazy(() =>
  import("../../pages/EndOfLifeDevice/EndOfLifeDevice")
);
const FailedTrackers = React.lazy(() =>
  import("../../pages/FailedTrackers/FailedTrackers")
);
const BeaconSettings = React.lazy(() =>
  import("../../pages/BeaconSettings/BeaconSettings")
);
const Wifi = React.lazy(() => import("../../pages/Wifi/Wifi"));
const DiagnosticPage = React.lazy(() =>
  import("../../pages/Diagnostic/DiagnosticPage")
);
const CustomBranding = React.lazy(() =>
  import("../../pages/CustomBranding/CustomBranding")
);
const BLESensorList = React.lazy(() =>
  import("../../pages/BLESensorList/BLESensorList")
);
const ImportBLESensors = React.lazy(() =>
  import("../../pages/BLESensorList/ImportBLESensors")
);
const BLESensorUpdate = React.lazy(() =>
  import("../../pages/BLESensorList/BLESensorUpdate")
);
const Notification = React.lazy(() =>
  import("@cleanup/pages/Notification/NotificationPage")
);
const NotificationUpdate = React.lazy(() =>
  import("@cleanup/pages/NotifcationUpdate/NotificationdUpdatePage")
);
const Partner = React.lazy(() => import("../../pages/Partner/Partner"));
const PartnerCreate = React.lazy(() =>
  import("../../pages/PartnerUpdate/PartnerCreate")
);
const PartnerUpdate = React.lazy(() =>
  import("../../pages/PartnerUpdate/PartnerUpdate")
);
const DevAccount = React.lazy(() =>
  import("../../pages/DevAccount/DevAccount")
);
const DevAccountUpdate = React.lazy(() =>
  import("../../pages/DevAccountUpdate/DevAccountUpdate")
);
const SigfoxAccount = React.lazy(() =>
  import("../../pages/SigfoxAccount/SigfoxAccount")
);

const BulkManage = React.lazy(() =>
  import("../../pages/BulkManage/BulkManage")
);
const BulkResultsDetail = React.lazy(() =>
  import("../../pages/BulkResultsDetail/BulkResultsDetail")
);
const UsageProfile = React.lazy(() =>
  import("../../pages/DeviceUsageProfile/UsageProfile")
);
const DeviceUsageProfileManage = React.lazy(() =>
  import("../../pages/DeviceUsageProfile/DeviceUsageProfileManage")
);
const UtilizationGraphAndList = React.lazy(() =>
  import("../../pages/UtilizationGraphAndList/UtilizationGraphAndList")
);
const ChannelUpdate = React.lazy(() =>
  import("../../pages/ChannelUpdate/ChannelUpdate")
);

const CallBackUpdatePage = React.lazy(() =>
  import("../../cleanup/pages/CallbacksUpdate/CallBackUpdatePage")
);

const ReportTemplateLibrary = React.lazy(() =>
  import("../../pages/ReportTemplateLibrary/ReportTemplateLibrary")
);

const ReportTemplateLibraryManage = React.lazy(() =>
  import("../../pages/ReportTemplateLibrary/ReportTemplateLibraryManage")
);

const MaintenancePrograms = React.lazy(() =>
  import("../../pages/MaintenancePrograms/MaintenancePrograms")
);
const MaintenanceProgramsManage = React.lazy(() =>
  import("../../pages/MaintenancePrograms/MaintenanceProgramsManage")
);
const BiDashBoardsPage = React.lazy(() =>
  import("@cleanup/pages/BiDashBoards/BiDashBoardsPage")
);
const MaintenanceDashboard = React.lazy(() =>
  import("../../pages/MaintenanceDashboard/MaintenanceDashboard")
);
const MaintenanceProgramDetails = React.lazy(() =>
  import("../../pages/MaintenanceDashboard/MaintenanceProgramDetails")
);
const MaintenanceProgramsStatistics = React.lazy(() =>
  import("../../pages/MaintenanceDashboard/MaintenanceProgramsStatistics")
);
const StartMaintenanceProgramBulk = React.lazy(() =>
  import("../../pages/MaintenanceDashboard/StartMaintenanceProgramBulk")
);
const WebhookPage = React.lazy(() =>
  import("@cleanup/pages/Webhooks/WebhookPage")
);
const ThirdPartyData = React.lazy(() =>
  import("../../pages/ThirdPartyData/ThirdPartyData")
);
const Geozone = React.lazy(() => import("../../pages/Geozone/Geozone.js"));
const ImportExportGeozones = React.lazy(() =>
  import("../../pages/Geozone/ImportExportGeozones.js")
);
const PriceModel = React.lazy(() =>
  import("../../pages/PriceModel/PriceModel.js")
);
const PriceModelUpdate = React.lazy(() =>
  import("../../pages/PriceModel/PriceModelUpdate.js")
);
const PriceIncreaseUpdate = React.lazy(() =>
  import("../../pages/PriceModel/PriceIncreaseUpdate.js")
);
const BiDashBoard = React.lazy(() =>
  import("../../pages/BiDashBoard/BiDashBoard")
);
const InvoicesPage = React.lazy(() =>
  import("../../pages/InvoicesPage/InvoicesPage")
);
const PerformBulkOperation = React.lazy(() =>
  import("../../pages/BulkOperations/PerformBulkOperation")
);
const Quotation = React.lazy(() => import("../../pages/Quotation/Quotation"));

const AllDashBoardsPage = React.lazy(() =>
  import("@cleanup/pages/AllDashBoards/AllDashBoardsPage")
);

export const adminRoutes = [
  {
    path: "/quotation",
    component: () => <Quotation />,
    categoryId /*expand parent sidebar menu*/: "sidebar-admin",
    subPages /*highlight child sidebar item for*/: [],
    hasMobileMode: true,
  },
  {
    path: "/quotation_detail",
    exact: false,
    component: () => <QuotationDetail />,
    categoryId /*expand parent sidebar menu*/: "sidebar-admin",
    subPages /*highlight child sidebar item for*/: [],
  },
  {
    path: "/assets_location",
    component: () => <AssetMap />,
    categoryId /*expand parent sidebar menu*/: "assets-nav-item",
    subPages /*highlight child sidebar item for*/: [],
    hasMobileMode: true,
  },
  {
    path: "/tracked_assets_update",
    component: () => <AssetDetail />,
    categoryId /*expand parent sidebar menu*/: "assets-nav-item",
    subPages /*highlight child sidebar item for*/: [],
    hasMobileMode: true,
  },
  {
    path: "/trackers_admin_update",
    exact: false,
    component: () => <TrackersAdminUpdate />,
    categoryId /*expand parent sidebar menu*/: "sidebar-admin",
    subPages /*highlight child sidebar item for*/: [],
  },
  {
    path: "/manufacturing_info_view",
    exact: false,
    component: () => <MainManufacturingInfo />,
    categoryId /*expand parent sidebar menu*/: "", // specific case -> handled in AdminLayout
    subPages /*highlight child sidebar item for*/: ["/trackers_admin_update"],
  },
  {
    path: "/areas",
    exact: false,
    component: () => <Geozone />,
    categoryId /*expand parent sidebar menu*/: "sidebar-admin",
    subPages /*highlight child sidebar item for*/: [
      "/areas_update",
      "/import_export_geozones",
    ],
  },
  {
    path: "/areas_partner",
    exact: false,
    component: () => <Geozone isPartner={true} />,
    categoryId /*expand parent sidebar menu*/: "sidebar-admin",
    subPages /*highlight child sidebar item for*/: [
      "/areas_update",
      "/import_export_geozones",
    ],
  },
  {
    path: "/areas_update",
    exact: false,
    component: () => <AreasUpdate />,
    categoryId /*expand parent sidebar menu*/: "sidebar-admin",
    subPages /*highlight child sidebar item for*/: [],
  },
  {
    path: "/import_export_geozones",
    exact: false,
    component: () => <ImportExportGeozones />,
    categoryId /*expand parent sidebar menu*/: "sidebar-admin",
  },
  {
    path: "/infrastructures",
    exact: false,
    component: () => <Infrastructure />,
    categoryId /*expand parent sidebar menu*/: "sidebar-admin",
  },
  {
    path: "/infrastructures_admin",
    exact: false,
    component: () => <Infrastructure />,
    categoryId /*expand parent sidebar menu*/: "sidebar-admin",
    subPages /*highlight child sidebar item for*/: ["/infrastructures_update"],
  },
  {
    path: "/infrastructures_update",
    exact: false,
    component: () => <InfrastructuresUpdate />,
    categoryId /*expand parent sidebar menu*/: "sidebar-admin",
  },
  {
    path: "/geolayers",
    exact: false,
    component: () => <Geolayers />,
    categoryId /*expand parent sidebar menu*/: "sidebar-admin",
    subPages /*highlight child sidebar item for*/: ["/geolayer_update"],
  },
  {
    path: "/geolayer_update",
    exact: false,
    component: () => <GeolayerUpdate />,
    categoryId /*expand parent sidebar menu*/: "sidebar-admin",
  },
  {
    path: "/geolayer_generator",
    exact: false,
    component: () => <GeolayerGenerator />,
    categoryId /*expand parent sidebar menu*/: "sidebar-admin",
    subPages /*highlight child sidebar item for*/: [
      "/geolayer_generator_manage",
    ],
  },
  {
    path: "/geolayer_generator_manage",
    exact: false,
    component: () => <GeolayerGeneratorManage />,
    categoryId /*expand parent sidebar menu*/: "sidebar-admin",
  },
  {
    path: "/manage_alerts",
    exact: false,
    component: () => <Alert />,
    categoryId /*expand parent sidebar menu*/: "sidebar-admin",
    subPages /*highlight child sidebar item for*/: ["/manage_alerts_update"],
  },
  {
    path: "/manage_alerts_update",
    exact: false,
    component: () => <AlertRuleUpdate />,
    categoryId /*expand parent sidebar menu*/: "sidebar-admin",
  },
  {
    path: "/utilization_scheme",
    exact: false,
    component: () => <UtilizationKpi />,
    categoryId /*expand parent sidebar menu*/: "sidebar-admin",
    subPages /*highlight child sidebar item for*/: [
      "/utilization_scheme_update",
    ],
  },
  {
    path: "/utilization_scheme_update",
    exact: false,
    component: () => <UtilizationKpiUpdate />,
    categoryId /*expand parent sidebar menu*/: "sidebar-admin",
  },
  {
    path: "/manage_visibility_filters",
    exact: false,
    component: () => <VisibilityFilters />,
    categoryId /*expand parent sidebar menu*/: "sidebar-admin",
    subPages /*highlight child sidebar item for*/: [
      "/manage_visibility_filters_update",
    ],
  },
  {
    path: "/manage_visibility_filters_update",
    exact: false,
    component: () => <ManageVisibilityFilters />,
    categoryId /*expand parent sidebar menu*/: "sidebar-admin",
  },
  {
    path: "/manage_organisation_filters_partner",
    exact: false,
    component: () => <OrganisationFilters isPartner={true} />,
    categoryId /*expand parent sidebar menu*/: "sidebar-admin",
    subPages /*highlight child sidebar item for*/: [
      "/manage_organisation_filters_update",
    ],
  },
  {
    path: "/manage_organisation_filters",
    exact: false,
    component: () => <OrganisationFilters />,
    categoryId /*expand parent sidebar menu*/: "sidebar-admin",
    subPages /*highlight child sidebar item for*/: [
      "/manage_organisation_filters_update",
    ],
  },
  {
    path: "/manage_organisation_filters_update",
    exact: false,
    component: () => <ManageOrganisationFilters />,
    categoryId /*expand parent sidebar menu*/: "sidebar-admin",
  },
  {
    path: "/flow_definition",
    exact: false,
    component: () => <FlowDefinition />,
    categoryId /*expand parent sidebar menu*/: "sidebar-admin",
    subPages /*highlight child sidebar item for*/: ["/flow_settings"],
  },
  {
    path: "/flow_settings",
    exact: false,
    component: () => <FlowSettings />,
    categoryId /*expand parent sidebar menu*/: "sidebar-admin",
  },
  {
    path: "/process_definition",
    exact: false,
    component: () => <ProcessDefinition />,
    categoryId /*expand parent sidebar menu*/: "sidebar-admin",
    subPages /*highlight child sidebar item for*/: [
      "/process_definition_update",
    ],
  },
  {
    path: "/process_definition_update",
    exact: false,
    component: () => <ProcessDefinitionUpdate />,
    categoryId /*expand parent sidebar menu*/: "sidebar-admin",
  },
  {
    path: "/users_admin",
    exact: false,
    component: () => <User />,
    categoryId /*expand parent sidebar menu*/: "sidebar-admin",
    subPages /*highlight child sidebar item for*/: ["/users_admin_update"],
  },

  {
    path: "/users_admin_update",
    exact: false,
    component: () => <UserUpdatePage />,
    categoryId /*expand parent sidebar menu*/: "sidebar-admin",
  },
  {
    path: "/image_library",
    exact: false,
    component: () => <ImageLibrary />,
    categoryId /*expand parent sidebar menu*/: "sidebar-admin",
    subPages /*highlight child sidebar item for*/: ["/image_library_manage"],
  },
  {
    path: "/image_library_manage",
    exact: false,
    component: () => <ImageLibraryManage />,
    categoryId /*expand parent sidebar menu*/: "sidebar-admin",
    subPages /*highlight child sidebar item for*/: [],
  },
  {
    path: "/image_library_manage_partner",
    exact: false,
    component: () => <ImageLibraryManage isPartner={true} />,
    categoryId /*expand parent sidebar menu*/: "sidebar-admin",
    subPages /*highlight child sidebar item for*/: [],
  },
  {
    path: "/pricemodel",
    exact: false,
    component: () => <PriceModel />,
    categoryId /*expand parent sidebar menu*/: "sidebar-admin",
    subPages /*highlight child sidebar item for*/: [
      "/pricemodel_manage",
      "/pricemodel_increase",
    ],
  },
  {
    path: "/pricemodel_update",
    exact: false,
    component: () => <PriceModelUpdate />,
    categoryId /*expand parent sidebar menu*/: "sidebar-admin",
    subPages /*highlight child sidebar item for*/: [],
  },
  {
    path: "/pricemodel_increase",
    exact: false,
    component: () => <PriceIncreaseUpdate />,
    categoryId /*expand parent sidebar menu*/: "sidebar-admin",
    subPages /*highlight child sidebar item for*/: [],
  },
  {
    path: "/organization",
    exact: false,
    component: () => <Organization />,
    categoryId /*expand parent sidebar menu*/: "sidebar-admin",
    subPages /*highlight child sidebar item for*/: ["/organization_update"],
  },
  {
    path: "/org_demo",
    exact: false,
    component: () => <OrganisationDemoSettings />,
    categoryId /*expand parent sidebar menu*/: "sidebar-admin",
    subPages /*highlight child sidebar item for*/: ["/org_demo_manage"],
  },
  {
    path: "/org_demo_manage",
    exact: false,
    component: () => <OrganisationDemoSettingsManage />,
    categoryId /*expand parent sidebar menu*/: "sidebar-admin",
    subPages /*highlight child sidebar item for*/: [],
  },
  {
    path: "/custom_branding",
    exact: false,
    component: () => <CustomBranding />,
    categoryId /*expand parent sidebar menu*/: "sidebar-admin",
    subPages /*highlight child sidebar item for*/: [],
  },
  {
    path: "/custom_branding_partner",
    exact: false,
    component: () => <CustomBranding isPartner={true} />,
    categoryId /*expand parent sidebar menu*/: "sidebar-admin",
    subPages /*highlight child sidebar item for*/: [],
  },
  {
    path: "/diagnostic_organisation",
    exact: false,
    component: () => <DiagnosticPage />,
    categoryId /*expand parent sidebar menu*/: "sidebar-admin",
    subPages /*highlight child sidebar item for*/: [],
  },

  {
    path: "/single_sign_on",
    exact: false,
    component: () => <SingleSignOn />,
    categoryId /*expand parent sidebar menu*/: "sidebar-admin",
  },
  {
    path: "/default_landing_page",
    exact: false,
    component: () => <OrganizationLandingPage />,
    categoryId /*expand parent sidebar menu*/: "sidebar-admin",
  },
  {
    path: "/default_landing_page_partner",
    exact: false,
    component: () => <OrganizationLandingPage isPartner={true} />,
    categoryId /*expand parent sidebar menu*/: "sidebar-admin",
  },
  {
    path: "/invoicing",
    exact: false,
    component: () => <Invoicing />,
    categoryId /*expand parent sidebar menu*/: "sidebar-admin",
  },

  {
    path: "/end_of_life",
    exact: false,
    component: () => <EndOfLifeDevice />,
    categoryId /*expand parent sidebar menu*/: "sidebar-admin",
  },
  {
    path: "/failed_trackers",
    exact: false,
    component: () => <FailedTrackers />,
    categoryId /*expand parent sidebar menu*/: "sidebar-admin",
  },
  {
    path: "/beacon_settings",
    exact: false,
    component: () => <BeaconSettings />,
    categoryId /*expand parent sidebar menu*/: "sidebar-admin",
  },
  {
    path: "/wifi",
    exact: false,
    component: () => <Wifi />,
    categoryId /*expand parent sidebar menu*/: "sidebar-admin",
  },
  {
    path: "/wifi_cellular_mismatch",
    exact: false,
    component: () => <WifiCellularMismatch />,
    categoryId /*expand parent sidebar menu*/: "sidebar-admin",
  },
  {
    path: "/product_plan",
    exact: false,
    component: () => <ProductPlan />,
    categoryId /*expand parent sidebar menu*/: "sidebar-admin",
  },
  {
    path: "/partners",
    exact: false,
    component: () => <Partner />,
    categoryId /*expand parent sidebar menu*/: "sidebar-admin",
    subPages /*highlight child sidebar item for*/: [
      "/partners_update",
      "/partners_create",
    ],
  },
  {
    path: "/notification",
    exact: false,
    component: () => <Notification />,
    categoryId /*expand parent sidebar menu*/: "sidebar-admin",
    subPages /*highlight child sidebar item for*/: ["/notification_update"],
  },
  {
    path: "/notification_update",
    exact: false,
    component: () => <NotificationUpdate />,
    categoryId /*expand parent sidebar menu*/: "sidebar-admin",
  },
  {
    path: "/invoicing_operations",
    exact: false,
    component: () => <InvoicingOperations />,
    categoryId /*expand parent sidebar menu*/: "sidebar-admin",
    subPages /*highlight child sidebar item for*/: [
      "/invoicing_operations_detail",
    ],
  },
  {
    path: "/invoicing_operations_detail",
    exact: false,
    component: () => <InvoicingOperationsDetail />,
    categoryId /*expand parent sidebar menu*/: "sidebar-admin",
  },
  {
    path: "/invoices",
    exact: false,
    component: () => <InvoicesPage />,
    categoryId /*expand parent sidebar menu*/: "sidebar-admin",
    subPages /*highlight child sidebar item for*/: ["/invoices_detail"],
  },
  {
    path: "/invoice_detail",
    exact: false,
    component: () => <InvoiceDetail />,
    categoryId /*expand parent sidebar menu*/: "sidebar-admin",
    subPages /*highlight child sidebar item for*/: [],
  },
  {
    path: "/invoices_detail",
    exact: false,
    component: () => <InvoiceDetail isSystem={true} />,
    categoryId /*expand parent sidebar menu*/: "sidebar-admin",
    subPages /*highlight child sidebar item for*/: [],
  },
  {
    path: "/partners_update",
    exact: false,
    component: () => <PartnerUpdate />,
    categoryId /*expand parent sidebar menu*/: "sidebar-admin",
  },
  {
    path: "/partners_create",
    exact: false,
    component: () => <PartnerCreate />,
    categoryId /*expand parent sidebar menu*/: "sidebar-admin",
  },
  {
    path: "/partners_details",
    exact: false,
    component: () => <OrganisationVisionPartnerUpdate />,
    categoryId /*expand parent sidebar menu*/: "sidebar-admin",
  },
  {
    path: "/ble_sensors",
    exact: false,
    component: () => <BLESensorList />,
    categoryId /*expand parent sidebar menu*/: "sidebar-admin",
  },
  {
    path: "/ble_sensor_list",
    exact: false,
    component: () => <BLESensorList />,
    categoryId /*expand parent sidebar menu*/: "sidebar-admin",
    subPages /*highlight child sidebar item for*/: [
      "/ble_sensor_update",
      "/ble_sensor_import",
    ],
  },
  {
    path: "/ble_sensor_partner",
    exact: false,
    component: () => <BLESensorList />,
    categoryId /*expand parent sidebar menu*/: "sidebar-admin",
    subPages /*highlight child sidebar item for*/: [
      "/ble_sensor_update",
      "/ble_sensor_import",
    ],
  },
  {
    path: "/ble_sensor_update",
    exact: false,
    component: () => <BLESensorUpdate />,
    categoryId /*expand parent sidebar menu*/: "sidebar-admin",
  },
  {
    path: "/ble_sensor_import",
    exact: false,
    component: () => <ImportBLESensors />,
    categoryId /*expand parent sidebar menu*/: "sidebar-admin",
  },
  {
    path: "/dev_account",
    exact: false,
    component: () => <DevAccount />,
    categoryId /*expand parent sidebar menu*/: "sidebar-admin",
  },
  {
    path: "/dev_account_admin",
    exact: false,
    component: () => <DevAccount />,
    categoryId /*expand parent sidebar menu*/: "sidebar-admin",
    subPages /*highlight child sidebar item for*/: ["/dev_account_update"],
  },
  {
    path: "/dev_account_update",
    exact: false,
    component: () => <DevAccountUpdate />,
    categoryId /*expand parent sidebar menu*/: "sidebar-admin",
  },
  {
    path: "/sigfox_account",
    exact: false,
    component: () => <SigfoxAccount />,
    categoryId /*expand parent sidebar menu*/: "sidebar-admin",
    subPages /*highlight child sidebar item for*/: [
      "/sigfox_account_update",
      "/sigfox_account_device_type_info",
    ],
  },
  {
    path: "/sigfox_account_update",
    exact: false,
    component: () => <SigfoxAccountUpdate />,
    categoryId /*expand parent sidebar menu*/: "sidebar-admin",
  },
  {
    path: "/stock",
    exact: false,
    component: () => <Stock />,
    categoryId /*expand parent sidebar menu*/: "sidebar-admin",
    subPages /*highlight child sidebar item for*/: ["/stock_update"],
  },
  {
    path: "/stock_update",
    exact: false,
    component: () => <StockUpdate />,
    categoryId /*expand parent sidebar menu*/: "sidebar-admin",
  },
  {
    path: "/sigfox_account_device_type_info",
    exact: false,
    component: () => <SigfoxAccountDeviceTypeInfo />,
    categoryId /*expand parent sidebar menu*/: "sidebar-admin",
    subPages /*highlight child sidebar item for*/: [],
  },
  {
    path: "/bulk_operations",
    exact: false,
    component: () => <PerformBulkOperation />,
    categoryId /*expand parent sidebar menu*/: "sidebar-admin",
  },
  {
    path: "/bulk_operations_admin",
    exact: false,
    component: () => <PerformBulkOperation />,
    categoryId /*expand parent sidebar menu*/: "sidebar-admin",
  },
  {
    path: "/bulk_operations_partner",
    exact: false,
    component: () => <PerformBulkOperation isPartner={true} />,
    categoryId /*expand parent sidebar menu*/: "sidebar-admin",
  },
  {
    path: "/support_tickets",
    exact: false,
    component: () => <ZendeskSupportTicket />,
    categoryId /*expand parent sidebar menu*/: "sidebar-admin",
  },
  {
    path: "/order_tickets",
    exact: false,
    component: () => <ZendeskOrderTicket />,
    categoryId /*expand parent sidebar menu*/: "sidebar-admin",
  },
  {
    path: "/maintenance_programs",
    exact: false,
    component: () => <MaintenancePrograms />,
    categoryId /*expand parent sidebar menu*/: "sidebar-admin",
    subPages /*highlight child sidebar item for*/: [
      "/maintenance_programs_manage",
    ],
  },
  {
    path: "/maintenance_programs_manage",
    exact: false,
    component: () => <MaintenanceProgramsManage />,
    categoryId /*expand parent sidebar menu*/: "sidebar-admin",
    subPages /*highlight child sidebar item for*/: [],
  },
  {
    path: "/maintenance_dashboard",
    exact: false,
    component: () => <MaintenanceDashboard />,
    categoryId /*expand parent sidebar menu*/: "maintenance",
    subPages /*highlight child sidebar item for*/: [],
  },
  {
    path: "/maintenance_done",
    exact: false,
    component: () => <MaintenanceDoneTable />,
    categoryId /*expand parent sidebar menu*/: "maintenance",
    subPages /*highlight child sidebar item for*/: [],
  },
  {
    path: "/maintenance_upcoming",
    exact: false,
    component: () => <MaintenanceUpcomingTable />,
    categoryId /*expand parent sidebar menu*/: "maintenance",
    subPages /*highlight child sidebar item for*/: [],
  },
  {
    path: "/maintenance_programs_stats",
    exact: false,
    component: () => <MaintenanceProgramsStatistics />,
    categoryId /*expand parent sidebar menu*/: "maintenance",
    subPages /*highlight child sidebar item for*/: [
      "/maintenance_programs_monitored_assets",
    ],
  },
  {
    path: "/maintenance_programs_details",
    exact: false,
    component: () => <MaintenanceProgramDetails />,
    categoryId /*expand parent sidebar menu*/: "maintenance",
    subPages /*highlight child sidebar item for*/: [],
  },
  {
    path: "/start_maintenance_program",
    exact: false,
    component: () => <StartMaintenanceProgramBulk />,
    categoryId /*expand parent sidebar menu*/: "maintenance",
  },
  {
    path: "/bi_dashboards",
    exact: false,
    component: () => <BiDashBoardsPage />,
    categoryId /*expand parent sidebar menu*/: "sidebar-admin",
    subPages /*highlight child sidebar item for*/: ["/bi_dashboard_manage"],
  },
  {
    path: "/bi_dashboard_manage",
    exact: false,
    component: () => <BiDashBoardManagePage />,
    categoryId /*expand parent sidebar menu*/: "sidebar-admin",
  },
  {
    path: "/bi_dashboards_partner",
    exact: false,
    component: () => <BiDashBoardsPage isPartner={true} />,
    categoryId /*expand parent sidebar menu*/: "sidebar-admin",
    subPages /*highlight child sidebar item for*/: [
      "/bi_dashboard_manage_partner",
    ],
  },
  {
    path: "/bi_dashboard_manage_partner",
    exact: false,
    component: () => <BiDashBoardManagePage isPartner={true} />,
    categoryId /*expand parent sidebar menu*/: "sidebar-admin",
  },
  {
    path: "/bulk_manage",
    exact: false,
    component: () => <BulkManage isSystem={true} />,
    categoryId /*expand parent sidebar menu*/: "sidebar-admin",
    subPages /*highlight child sidebar item for*/: [
      "/bulk_results_detail",
      "/bulk_operations",
    ],
  },
  {
    path: "/bulk_manage_admin",
    exact: false,
    component: () => <BulkManage />,
    categoryId /*expand parent sidebar menu*/: "sidebar-admin",
    subPages /*highlight child sidebar item for*/: [
      "/bulk_results_detail_admin",
      "/bulk_operations_admin",
    ],
  },
  {
    path: "/bulk_manage_partner",
    exact: false,
    component: () => <BulkManage isPartner={true} />,
    categoryId /*expand parent sidebar menu*/: "sidebar-admin",
    subPages /*highlight child sidebar item for*/: [
      "/bulk_results_detail_partner",
      "/bulk_operations_partner",
    ],
  },
  {
    path: "/bulk_results_detail",
    exact: false,
    component: () => <BulkResultsDetail />,
    categoryId /*expand parent sidebar menu*/: "sidebar-admin",
  },
  {
    path: "/device_usage_profile",
    exact: true,
    component: () => <UsageProfile />,
    categoryId /*expand parent sidebar menu*/: "sidebar-admin",
    subPages /*highlight child sidebar item for*/: [
      "/device_usage_profile_manage",
    ],
  },
  {
    path: "/device_usage_profile_manage",
    exact: false,
    component: () => <DeviceUsageProfileManage />,
    categoryId /*expand parent sidebar menu*/: "sidebar-admin",
    subPages /*highlight child sidebar item for*/: [],
  },
  {
    path: "/api-docs",
    exact: false,
    component: () => <ApiDocs />,
    categoryId /*expand parent sidebar menu*/: "external-api-group-nav-item",
    subPages /*highlight child sidebar item for*/: [],
  },
  {
    path: "/api-access",
    exact: false,
    component: () => <ApiAccess />,
    categoryId /*expand parent sidebar menu*/: "external-api-group-nav-item",
    subPages /*highlight child sidebar item for*/: [],
  },
  {
    path: "/client-app-api",
    exact: false,
    component: () => <ClientAppApi />,
    categoryId /*expand parent sidebar menu*/: "external-api-group-nav-item",
    subPages /*highlight child sidebar item for*/: [],
  },
  {
    path: "/role_manager",
    exact: false,
    component: () => <RoleManager isManager={true} />,
    categoryId /*expand parent sidebar menu*/: "sidebar-admin",
    subPages /*highlight child sidebar item for*/: ["/role_manager_update"],
  },
  {
    path: "/role_manager_update",
    exact: false,
    component: () => <RoleManagerUpdate />,
    categoryId /*expand parent sidebar menu*/: "admin-experimental",
  },
  {
    path: "/role_manager_admin_update",
    exact: false,
    component: () => <RoleManagerUpdate />,
    categoryId /*expand parent sidebar menu*/: "admin-experimental",
  },
  {
    path: "/role_manager_admin",
    exact: false,
    component: () => <RoleManager />,
    categoryId /*expand parent sidebar menu*/: "sidebar-admin",
    subPages /*highlight child sidebar item for*/: [
      "/profile",
      "/role_manager_admin_update",
    ],
  },
  {
    path: "/internal-api",
    exact: false,
    component: () => <InternalApi />,
    categoryId /*expand parent sidebar menu*/: "system-developer",
    subPages /*highlight child sidebar item for*/: [],
  },
  {
    path: "/internal_plans",
    exact: false,
    component: () => <InternalPlan />,
    categoryId /*expand parent sidebar menu*/: "system-developer",
    subPages /*highlight child sidebar item for*/: [],
  },
  {
    path: "/utilization",
    exact: false,
    component: () => <Utilization />,
    categoryId /*expand parent sidebar menu*/: "", // specific case -> handled in AdminLayout
    subPages /*highlight child sidebar item for*/: [
      "/utilization_graph_and_list",
    ],
  },
  {
    path: "/processes",
    exact: false,
    component: () => <Processes />,
    categoryId /*expand parent sidebar menu*/: "", // specific case -> handled in AdminLayout
    subPages /*highlight child sidebar item for*/: ["/process_statistics"],
  },
  {
    path: "/process_statistics",
    exact: false,
    component: () => <ProcessStatistics />,
    categoryId /*expand parent sidebar menu*/: "", // specific case -> handled in AdminLayout
    subPages /*highlight child sidebar item for*/: [],
  },
  {
    path: "/manufacturing",
    exact: false,
    component: () => <Manufacturing />,
    categoryId /*expand parent sidebar menu*/: "", // specific case -> handled in AdminLayout
    subPages /*highlight child sidebar item for*/: [
      "/manufacturing_order_detail",
    ],
  },
  {
    path: "/manufacturing_order_detail",
    exact: false,
    component: () => <ManufacturingOrderDetail />,
    categoryId /*expand parent sidebar menu*/: "", // specific case -> handled in AdminLayout
    subPages /*highlight child sidebar item for*/: [],
  },
  {
    path: "/suez_dashboard",
    exact: false,
    component: () => <SuezDashBoard />,
    categoryId /*expand parent sidebar menu*/: "dashboard",
    subPages /*highlight child sidebar item for*/: [],
  },
  {
    path: "/bi_dashboard",
    exact: false,
    component: () => <BiDashBoard />,
    categoryId /*expand parent sidebar menu*/: "dashboard",
    subPages /*highlight child sidebar item for*/: [],
  },
  {
    path: "/bi_dashboard/:id",
    exact: false,
    component: () => <BiDashBoard />,
    categoryId /*expand parent sidebar menu*/: "dashboard",
    subPages /*highlight child sidebar item for*/: [],
  },
  {
    path: "/reports",
    exact: false,
    component: () => <Report />,
    categoryId /*expand parent sidebar menu*/: "report",
    subPages /*highlight child sidebar item for*/: ["/create_report"],
  },
  {
    path: "/create_report",
    exact: false,
    component: () => <CreateReport />,
    categoryId /*expand parent sidebar menu*/: "report",
    subPages /*highlight child sidebar item for*/: [],
  },
  {
    path: "/report_schedule",
    exact: false,
    component: () => <ReportSchedule />,
    categoryId /*expand parent sidebar menu*/: "report",
  },
  {
    path: "/active_alerts_dashboard",
    exact: false,
    component: () => <ActiveAlertsDashboard />,
    categoryId /*expand parent sidebar menu*/: "sidebar-alerts",
    subPages /*highlight child sidebar item for*/: [],
  },
  {
    path: "/historical_alerts_dashboard",
    exact: false,
    component: () => <HistoricalAlertsDashboard />,
    categoryId /*expand parent sidebar menu*/: "sidebar-alerts",
    subPages /*highlight child sidebar item for*/: [],
  },
  {
    path: "/active_alerts_list",
    exact: false,
    component: () => <ActiveAlertsList />,
    categoryId /*expand parent sidebar menu*/: "sidebar-alerts",
    subPages /*highlight child sidebar item for*/: [],
  },
  {
    path: "/historical_alerts_list",
    exact: false,
    component: () => <HistoricalAlertsList />,
    categoryId /*expand parent sidebar menu*/: "sidebar-alerts",
    subPages /*highlight child sidebar item for*/: [],
  },
  {
    path: "/geozones_dashboard",
    exact: false,
    component: () => <GeozonesDashboard />,
    categoryId /*expand parent sidebar menu*/: "sidebar-geozones",
    subPages /*highlight child sidebar item for*/: [],
  },
  {
    path: "/geozones_list",
    exact: false,
    component: () => <GeozonesList />,
    categoryId /*expand parent sidebar menu*/: "sidebar-geozones",
    subPages /*highlight child sidebar item for*/: ["/geozone_individual"],
  },
  {
    path: "/geozones_tag_list",
    exact: false,
    component: () => <GeozonesTagList />,
    categoryId /*expand parent sidebar menu*/: "sidebar-geozones",
    subPages /*highlight child sidebar item for*/: [],
  },
  {
    path: "/geozone_tags_manage",
    exact: false,
    component: () => <GeozoneTagManage />,
    categoryId /*expand parent sidebar menu*/: "sidebar-geozones",
    subPages /*highlight child sidebar item for*/: [],
  },
  {
    path: "/update_geozone_tag",
    exact: false,
    component: () => <UpdateGeozoneTag />,
    categoryId /*expand parent sidebar menu*/: "sidebar-geozones",
    subPages /*highlight child sidebar item for*/: [],
  },
  {
    path: "/asset_tags_manage_admin",
    exact: false,
    component: () => <AssetTagManage isHomeMenu={true} />,
    categoryId /*expand parent sidebar menu*/: "sidebar-geozones",
    subPages /*highlight child sidebar item for*/: [],
  },
  {
    path: "/asset_tags_manage",
    exact: false,
    component: () => <AssetTagManage />,
    categoryId /*expand parent sidebar menu*/: "sidebar-geozones",
    subPages /*highlight child sidebar item for*/: [],
  },
  {
    path: "/update_device_tag",
    exact: false,
    component: () => <UpdateDeviceTag />,
    categoryId /*expand parent sidebar menu*/: "sidebar-geozones",
    subPages /*highlight child sidebar item for*/: [],
  },
  {
    path: "/system_tag_manage",
    exact: false,
    component: () => <SystemTagManage />,
    categoryId /*expand parent sidebar menu*/: "sidebar-geozones",
    subPages /*highlight child sidebar item for*/: [],
  },
  {
    path: "/system_tag",
    exact: false,
    component: () => <SystemTagList />,
    categoryId /*expand parent sidebar menu*/: "sidebar-geozones",
    subPages /*highlight child sidebar item for*/: ["system_tag_manage"],
  },
  {
    path: "/partner_tag_manage",
    exact: false,
    component: () => <PartnerTagManage />,
    categoryId /*expand parent sidebar menu*/: "sidebar-geozones",
    subPages /*highlight child sidebar item for*/: [],
  },
  {
    path: "/partner_tag",
    exact: false,
    component: () => <PartnerTagList />,
    categoryId /*expand parent sidebar menu*/: "sidebar-geozones",
    subPages /*highlight child sidebar item for*/: ["partner_tag_manage"],
  },
  {
    path: "/geozone_individual",
    exact: false,
    component: () => <GeozoneStatistics />,
    categoryId /*expand parent sidebar menu*/: "sidebar-geozones",
    subPages /*highlight child sidebar item for*/: [],
  },
  {
    path: "/utilization_graph_and_list",
    exact: false,
    component: () => <UtilizationGraphAndList />,
    categoryId /*expand parent sidebar menu*/: "",
    subPages /*highlight child sidebar item for*/: [],
  },
  {
    path: "/channel_update",
    exact: false,
    component: () => <ChannelUpdate />,
    categoryId /*expand parent sidebar menu*/: "",
    subPages /*highlight child sidebar item for*/: [],
  },
  {
    path: "/callbacks_update",
    exact: false,
    component: () => <CallBackUpdatePage />,
    categoryId /*expand parent sidebar menu*/: "",
    subPages /*highlight child sidebar item for*/: [],
  },
  {
    path: "/report_template_library",
    exact: true,
    component: () => <ReportTemplateLibrary />,
    categoryId /*expand parent sidebar menu*/: "sidebar-admin",
    subPages /*highlight child sidebar item for*/: [
      "/report_template_library_manage",
    ],
  },
  {
    path: "/report_template_library_manage",
    exact: false,
    component: () => <ReportTemplateLibraryManage />,
    categoryId /*expand parent sidebar menu*/: "sidebar-admin",
    subPages /*highlight child sidebar item for*/: [],
  },
  {
    path: "/report_template_library_partner",
    exact: true,
    component: () => <ReportTemplateLibrary isPartner={true} />,
    categoryId /*expand parent sidebar menu*/: "sidebar-admin",
    subPages /*highlight child sidebar item for*/: [
      "/report_template_library_manage_partner",
    ],
  },
  {
    path: "/report_template_library_manage_partner",
    exact: false,
    component: () => <ReportTemplateLibraryManage isPartner={true} />,
    categoryId /*expand parent sidebar menu*/: "sidebar-admin",
    subPages /*highlight child sidebar item for*/: [],
  },
  {
    path: "/",
    exact: true,
    component: () => <></>,
  },
  {
    path: "/factory_upload",
    exact: false,
    component: () => <FactoryUpload />,
    categoryId /*expand parent sidebar menu*/: "sidebar-admin",
    subPages /*highlight child sidebar item for*/: ["factory_upload_update"],
  },
  {
    path: "/factory_upload_update",
    exact: false,
    component: () => <FactoryUploadUpdate />,
    categoryId /*expand parent sidebar menu*/: "sidebar-admin",
    subPages /*highlight child sidebar item for*/: [],
  },
  {
    path: "/factory_order_create",
    exact: false,
    component: () => <CreateFactoryOrder />,
    categoryId /*expand parent sidebar menu*/: "sidebar-admin",
    subPages /*highlight child sidebar item for*/: [],
  },
  {
    path: "/trackers_admin",
    exact: true,
    component: () => <AssetTrackersAdmin />,
    categoryId /*expand parent sidebar menu*/: "sidebar-admin",
    subPages /*highlight child sidebar item for*/: [
      "/trackers_admin_update",
      "/import_tracker_settings",
    ],
  },
  {
    path: "/import_tracker_settings_admin",
    exact: true,
    component: () => <ImportTrackerSettings />,
    categoryId /*expand parent sidebar menu*/: "sidebar-admin",
  },
  {
    path: "/import_tracker_settings_partner",
    exact: true,
    component: () => <ImportTrackerSettings isPartner={true} />,
    categoryId /*expand parent sidebar menu*/: "sidebar-admin",
  },
  {
    path: "/import_tracker_settings",
    exact: true,
    component: () => <ImportTrackerSettings isSystem={true} />,
    categoryId /*expand parent sidebar menu*/: "sidebar-admin",
  },
  {
    path: "/trackers",
    exact: true,
    component: () => <AssetTrackersAdmin isSystem={true} />,
    categoryId /*expand parent sidebar menu*/: "sidebar-admin",
  },
  {
    path: "/location_history",
    exact: true,
    component: () => <LocationHistory />,
    categoryId /*expand parent sidebar menu*/: "sidebar-admin",
  },
  {
    path: "/all_dashboards",
    exact: true,
    component: () => <AllDashBoardsPage />,
    categoryId /*expand parent sidebar menu*/: "sidebar-admin",
  },
  {
    path: "/trackers_partner",
    exact: true,
    component: () => <AssetTrackersAdmin isPartner={true} />,
    categoryId /*expand parent sidebar menu*/: "sidebar-admin",
  },
  {
    path: "/tracked_assets",
    exact: true,
    component: () => <AssetListReact />,
    categoryId /*expand parent sidebar menu*/: "assets-nav-item",
    subPages /*highlight child sidebar item for*/: ["/tracked_assets_update"],
    hasMobileMode: true,
  },
  {
    path: "/tracker_product",
    exact: true,
    component: () => <TrackerProduct />,
    categoryId /*expand parent sidebar menu*/: "assets-nav-item",
    subPages /*highlight child sidebar item for*/: ["/tracker_product_detail"],
  },
  {
    path: "/tracker_product_detail",
    exact: true,
    component: () => <TrackerProductDetail />,
    categoryId /*expand parent sidebar menu*/: "assets-nav-item",
    subPages /*highlight child sidebar item for*/: [],
  },
  {
    path: "/product_auto_upgrade",
    exact: true,
    component: () => <ProductAutoUpgrade />,
    categoryId /*expand parent sidebar menu*/: "assets-nav-item",
    subPages /*highlight child sidebar item for*/: [
      "/product_auto_upgrade_detail",
    ],
  },
  {
    path: "/product_auto_upgrade_detail",
    exact: true,
    component: () => <ProductAutoUpgradeDetail />,
    categoryId /*expand parent sidebar menu*/: "assets-nav-item",
    subPages /*highlight child sidebar item for*/: [],
  },
  {
    path: "/product_regexp_filter",
    exact: true,
    component: () => <ProductRegexpFilter />,
    categoryId /*expand parent sidebar menu*/: "assets-nav-item",
    subPages /*highlight child sidebar item for*/: [
      "/product_auto_upgrade_detail",
    ],
  },
  {
    path: "/product_regexp_filter_detail",
    exact: true,
    component: () => <ProductRegexpFilterDetail />,
    categoryId /*expand parent sidebar menu*/: "assets-nav-item",
    subPages /*highlight child sidebar item for*/: [],
  },
  {
    path: "/webhooks",
    component: () => <WebhookPage />,
    categoryId /*expand parent sidebar menu*/: "",
    subPages /*highlight child sidebar item for*/: ["/callbacks_update"],
  },
  {
    path: "/third_party_data",
    component: () => <ThirdPartyData />,
    categoryId /*expand parent sidebar menu*/: "",
    subPages /*highlight child sidebar item for*/: ["/channel_update"],
  },
  {
    path: "/organization_update",
    component: () => <OrganizationDetail />,
    categoryId /*expand parent sidebar menu*/: "",
    subPages /*highlight child sidebar item for*/: [],
  },
  {
    path: "/support",
    component: () => <Support />,
    categoryId /*expand parent sidebar menu*/: "",
    subPages /*highlight child sidebar item for*/: [],
  },
  {
    path: "/support_partner",
    component: () => <Support isPartner={true} />,
    categoryId /*expand parent sidebar menu*/: "",
    subPages /*highlight child sidebar item for*/: [],
  },
  {
    path: "/rma_declaration",
    component: () => <RMADeclaration />,
    categoryId /*expand parent sidebar menu*/: "",
    subPages /*highlight child sidebar item for*/: ["/rma_declaration_manage"],
  },
  {
    path: "/rma_declaration_manage",
    exact: false,
    component: () => <RMADeclarationManage />,
    categoryId /*expand parent sidebar menu*/: "",
    subPages /*highlight child sidebar item for*/: [],
  },
  {
    path: "/rma_declaration_view",
    exact: false,
    component: () => <RMADeclarationView />,
    categoryId /*expand parent sidebar menu*/: "",
    subPages /*highlight child sidebar item for*/: [
      "/rma_declaration_view_details",
    ],
  },
  {
    path: "/rma_declaration_view_details",
    exact: false,
    component: () => <RMADeclarationViewDetails />,
    categoryId /*expand parent sidebar menu*/: "",
    subPages /*highlight child sidebar item for*/: [],
  },
  {
    path: "/custom_i18n_messages",
    exact: false,
    component: () => <CustomI18nMessages />,
    categoryId /*expand parent sidebar menu*/: "sidebar-admin",
    subPages /*highlight child sidebar item for*/: [
      "/custom_i18n_messages_manage",
    ],
  },
  {
    path: "/custom_i18n_messages_manage",
    exact: false,
    component: () => <CustomI18nMessagesManage />,
    categoryId /*expand parent sidebar menu*/: "sidebar-admin",
  },
  {
    path: "/audit_log",
    exact: false,
    component: () => <AuditLog />,
    categoryId /*expand parent sidebar menu*/: "sidebar-admin",
    subPages /*highlight child sidebar item for*/: ["/audit_log_view"],
  },
  {
    path: "/audit_log_view",
    exact: false,
    component: () => <AuditLogView />,
    categoryId /*expand parent sidebar menu*/: "sidebar-admin",
  },
  {
    path: "/audit_log_partner",
    exact: false,
    component: () => <AuditLog isPartner={true} />,
    categoryId /*expand parent sidebar menu*/: "sidebar-admin",
    subPages /*highlight child sidebar item for*/: ["/audit_log_view_partner"],
  },
  {
    path: "/audit_log_view_partner",
    exact: false,
    component: () => <AuditLogView isPartner={true} />,
    categoryId /*expand parent sidebar menu*/: "sidebar-admin",
  },
  {
    path: "/legal_document",
    exact: false,
    component: () => <LegalDocument />,
    categoryId /*expand parent sidebar menu*/: "sidebar-admin",
  },
  {
    path: "/legal_document_import",
    exact: false,
    component: () => <LegalDocumentImport />,
    categoryId /*expand parent sidebar menu*/: "sidebar-admin",
  },
  {
    path: "/legal_document_send",
    exact: false,
    component: () => <LegalDocumentSend />,
    categoryId /*expand parent sidebar menu*/: "sidebar-admin",
  },
  {
    path: "/legal_document_template",
    exact: false,
    component: () => <LegalDocumentTemplate />,
    categoryId /*expand parent sidebar menu*/: "sidebar-admin",
  },
  {
    path: "/legal_document_template_manage",
    exact: false,
    component: () => <LegalDocumentTemplateManage />,
    categoryId /*expand parent sidebar menu*/: "sidebar-admin",
  },
  {
    path: "/legal_document_view",
    exact: false,
    component: () => <LegalDocumentView />,
    categoryId /*expand parent sidebar menu*/: "sidebar-admin",
  },
  {
    path: "/metar_weather_location",
    exact: false,
    component: () => <MetarWeatherLocation />,
    categoryId /*expand parent sidebar menu*/: "sidebar-admin",
    subPages /*highlight child sidebar item for*/: [
      "/metar_weather_location_manage",
    ],
  },
  {
    path: "/metar_weather_location_manage",
    exact: false,
    component: () => <MetarWeatherLocationManage />,
    categoryId /*expand parent sidebar menu*/: "sidebar-admin",
  },
  {
    path: "/permission",
    exact: false,
    component: () => <PermissionPage />,
  },
  {
    path: "/no_access",
    exact: false,
    component: () => <NoAccessPage />,
  },
];

export const routes = [
  {
    path: "/login_mobile",
    exact: true,
    component: () => (
      <Suspense fallback={<Loading />}>
        <LoginMobile />
      </Suspense>
    ),
  },
  {
    path: "/login",
    exact: true,
    component: () => (
      <Suspense fallback={<Loading />}>
        <Login />
      </Suspense>
    ),
  },
  {
    path: "/oauth2/authorize",
    exact: true,
    component: () => (
      <Suspense fallback={<Loading />}>
        <OAuth2Authorize />
      </Suspense>
    ),
  },
  {
    path: "/logout",
    exact: true,
    component: () => (
      <Suspense fallback={<Loading />}>
        <Logout />
      </Suspense>
    ),
  },
  {
    path: "/confirm",
    exact: true,
    component: () => (
      <Suspense fallback={<Loading />}>
        <Confirm />
      </Suspense>
    ),
  },
  {
    path: "/reset_password/change_password_expires",
    exact: true,
    component: () => (
      <Suspense fallback={<Loading />}>
        <ChangePasswordExpired />
      </Suspense>
    ),
  },
  {
    path: "/reset_password/forget_password",
    exact: true,
    component: () => (
      <Suspense fallback={<Loading />}>
        <ForgetPassword />
      </Suspense>
    ),
  },
  {
    path: "/reset_password/change_password",
    exact: true,
    component: () => (
      <Suspense fallback={<Loading />}>
        <ChangePassword />
      </Suspense>
    ),
  },
  {
    path: "/email_verification",
    exact: true,
    component: () => (
      <Suspense fallback={<Loading />}>
        <EmailVerification />
      </Suspense>
    ),
  },
  {
    path: "/complete_user_info",
    exact: true,
    component: () => (
      <Suspense fallback={<Loading />}>
        <CompleteUserInfo />
      </Suspense>
    ),
  },
  {
    path: "/activate_invitation",
    exact: true,
    component: () => (
      <Suspense fallback={<Loading />}>
        <ActivateInvitation />
      </Suspense>
    ),
  },
  {
    path: "/profile",
    exact: true,
    component: () => (
      <Suspense fallback={<Loading />}>
        <Profile />
      </Suspense>
    ),
  },
  {
    path: "/zendesk/sso",
    exact: true,
    component: () => (
      <Suspense fallback={<Loading />}>
        <Helpdesk />
      </Suspense>
    ),
  },
  {
    path: "/jwt",
    exact: true,
    component: () => (
      <Suspense fallback={<Loading />}>
        <Helpdesk />
      </Suspense>
    ),
  },
  {
    path: "/invoice_addendum",
    exact: true,
    component: () => (
      <Suspense fallback={<Loading />}>
        <InvoiceAddendum />
      </Suspense>
    ),
  },
  {
    path: "/403",
    exact: true,
    component: () => (
      <Suspense fallback={<Loading />}>
        <ErrorPage />
      </Suspense>
    ),
  },
];

// add all admin routes as 1 route
const adminPath = [];
adminRoutes.forEach((route) => {
  if (Array.isArray(route.path)) {
    adminPath.push(...route.path);
  } else {
    adminPath.push(route.path);
  }
});

routes.push({
  path: adminPath,
  exact: true,
  component: () => (
    <Suspense fallback={<Loading />}>
      <AppLayout />
    </Suspense>
  ),
});

// add not found catch-all
routes.push({
  path: "/",
  exact: false,
  component: () => (
    <Suspense fallback={<Loading />}>
      <NotFoundPage />
    </Suspense>
  ),
});
